<template>
  <div class="settings-section">
    <h2>Features</h2>
    <p>These are the features available for your account.</p>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
    <div class="feature-box">
      <div class="feature-list" v-for="(feature, index) in availableFeatures" :key="index">
        <div>
          <p>{{ $t(feature.featureNameKey) }}</p>
          <p class="description">{{ $t(feature.featureDescriptionKey) }}</p>
        </div>
        <div>
          <span v-if="hasFeature(feature.name)">Enabled </span>
          <v-btn v-else @click="buyFeature(feature.name)">Buy</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
DIV.settings-section .feature-box {
  margin-top: 1em;
  border: 1px solid silver;
  border-radius: 1em;
  padding: 1em;
}

DIV.settings-section .feature-list {
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 1em;
}

.feature-list .description {
  font-size: 80%;
}
</style>

<script>
import userFeatures from '../../mixins/userFeatures'
import { featureToggles } from '@/featureToggles'

export default {
  name: 'SettingsFeatures',
  mixins: [userFeatures],

  data: () => ({
    error: null
  }),

  computed: {
    availableFeatures () {
      return featureToggles.filter(feature => feature.userFeature)
    }
  },

  methods: {
    buyFeature (featureName) {
      fetch('/api/signup/features/purchase', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ featureName })
      })
        .then(response => {
          if (response.status === 200) {
            window.location.reload()
            return response
          } else {
            throw new Error(response.statusText)
          }
        })
        .catch(error => {
          this.error = error
        })
    }
  }
}
</script>
