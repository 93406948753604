<template>
  <div>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <AddChoreDialog :show="showAddDialog" @cancel="showAddDialog = false" @save="saveChore" />
    <v-toolbar flat>
      <v-btn color="primary" @click="addChore">
        Add
      </v-btn>
    </v-toolbar>

    <div class="chores-table">
      <draggable v-model="chores" @change="onChange">
        <transition-group>
          <div v-for="(item, index) in chores" :key="index" class="chore">
            <div>{{ item.choreSubject }} {{ item.id }}, {{ item.previousChoreId }}</div>
            <div class="action">
              <v-icon @click="editChore(item)" color="primary">mdi-pencil</v-icon>
              <v-icon @click="deleteChore(item)" color="primary">mdi-delete</v-icon>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<style>
DIV.chore {
  display: grid;
  grid-template-columns: 80% auto;
  border: thin solid silver;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

DIV.chore .action {
  display: flex;
  justify-content: flex-end;
}

DIV.chores-table {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-top: 10px;
}
</style>

<script>
import draggable from 'vuedraggable'
import AddChoreDialog from '../chores/AddChoreDialog.vue'

export default {
  name: 'SettingsChores',

  components: { AddChoreDialog, draggable },

  data: () => ({
    error: null,
    chores: [],
    showAddDialog: false
  }),

  beforeMount () {
    this.getChores()
  },

  methods: {
    getChores () {
      fetch('/api/chores', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            return response
          } else {
            throw new Error(response.statusText)
          }
        })
        .then(response => response.json())
        .then(data => {
          this.chores = data
        })
        .catch(error => {
          console.log(error)
          this.error = error
        })
    },

    addChore () {
      this.showAddDialog = true
    },

    editChore (item) {
      console.log('editChore', item)
    },

    deleteChore (item) {
      fetch(`/api/chores/${item.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.getChores()
          } else {
            throw new Error(response.statusText)
          }
        })
        .catch(error => {
          console.log(error)
          this.error = error
        })
    },

    saveChore (item) {
      fetch('/api/chores', {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.showAddDialog = false
            this.getChores()
          } else {
            throw new Error(response.statusText)
          }
        })
        .catch(error => {
          console.log(error)
          this.error = error
        })
    },

    onChange (event) {
      console.log('onChange', event)
      console.log(this.chores)

      const movedElementId = event.moved.element.id
      const newPredecessorId = event.moved.newIndex === 0 ? null : this.chores[event.moved.newIndex - 1].id
      const movedElement = this.chores.find((element) => element.id === movedElementId)
      movedElement.previousChoreId = newPredecessorId

      if (this.chores[0].previousChoreId !== null) {
        this.chores[0].previousChoreId = null
      }

      fetch(`/api/chores/${movedElement.id}`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(movedElement)
      })
        .then(response => {
          if (response.status === 200) {
            this.getChores()
          } else {
            throw new Error(response.statusText)
          }
        })
        .catch(error => {
          console.log(error)
          this.error = error
        })
    }

  }
}
</script>
