<template>
  <h1>Overview</h1>
</template>

<script>
export default {
  name: 'SettingsOverview'
}
</script>
```
