<template>
  <div>
    <UserProfile />
    <AdminFeatures v-if="$keycloak.realmAccess.roles.includes('granja-admin')" />
  </div>
</template>

<script>
import AdminFeatures from './AdminFeatures.vue'
import UserProfile from './UserProfile.vue'

export default {
  name: 'SettingsAccount',

  components: {
    AdminFeatures,
    UserProfile
  }
}
</script>
