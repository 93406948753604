<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="item in menuItems" :key="item.link" :to="item.link" :data-cy="item.dataCy">{{ item.text }}</v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
import i18n from '@/i18n'
import userFeatures from '@/mixins/userFeatures'

export default {
  name: 'SettingsHome',
  components: {},
  mixins: [userFeatures],
  data () {
    return {
      tab: null
    }
  },

  computed: {
    menuItems () {
      const items = []
      items.push(
        {
          text: i18n.t('settings.tab.overview'),
          link: '/settings/overview',
          dataCy: 'settingsOverview'
        })

      // TODO check user profile as well for features
      if (this.$keycloak.realmAccess.roles.includes('granja-admin')) {
        items.push(
          {
            text: i18n.t('settings.tab.account'),
            link: '/settings/account',
            dataCy: 'settingsAccount'
          })
        items.push(
          {
            text: i18n.t('settings.tab.users'),
            link: '/settings/users',
            dataCy: 'settingsUsers'
          })

        if (this.hasFeature('zones')) {
          items.push(
            {
              text: i18n.t('settings.tab.zones'),
              link: '/settings/zones',
              dataCy: 'settingsZones'
            })
        }

        if (this.hasFeature('chores')) {
          items.push(
            {
              text: i18n.t('settings.tab.chores'),
              link: '/settings/chores',
              dataCy: 'settingsChores'
            })
        }
      }
      return items
    }
  }
}
</script>
