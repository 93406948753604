<template>
  <div class="settings-section">
    <h2>User profile</h2>
    <div class="profile-entries">
      <div>Username:</div>
      <div data-cy="username">{{ this.$keycloak.userName }}</div>
    </div>
    <div class="profile-entries">
      <div>Full name:</div>
      <div>{{ this.$keycloak.fullName }}</div>
    </div>
    <div class="profile-entries">
      <div>email:</div>
      <div>{{ userProfile.email }}</div>
    </div>
    <div class="profile-entries">
      <div>Roles:</div>
      <div>{{ this.$keycloak.realmAccess.roles }}</div>
    </div>
  </div>
</template>

<style>
DIV.profile-entries {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 1em;
}
</style>

<script>
export default {
  name: 'SettingsUserProfile',

  data: () => ({
    userProfile: {}
  }),

  beforeMount () {
    this.$keycloak.loadUserProfile()
      .then((profile) => {
        this.userProfile = profile
      })
  }
}
</script>
