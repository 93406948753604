<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>Add new chore </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field v-model="chore.choreSubject" :label="$t('chores.tableHeaders.choreSubject')"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDialog" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn data-cy="saveDialog" color="primary" @click="$emit('save', chore)">Done</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ActionDoneDialog',

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    chore: {}
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  }
}
</script>
