<template>
  <div class="settings-section">
    <v-data-table :headers="headers" :items="users" :items-per-page="5" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-btn data-cy="addUser" color="primary" @click="addUser">Add user</v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'SettingsAdminUsers',

  data: () => ({
    users: [],
    headers: [
      { text: 'Username', value: 'username' },
      { text: 'Full name', value: 'fullName' },
      { text: 'Email', value: 'email' }
    ]
  })

}
</script>
